<script context="module" lang="ts">
  export type SpinnerType =
    | "plane"
    | "chase"
    | "bounce"
    | "wave"
    | "pulse"
    | "flow"
    | "swing"
    | "circle"
    | "circle-fade"
    | "grid"
    | "fold"
    | "wander";

  export interface Props {
    type: SpinnerType;
  }
</script>

<script lang="ts">
  let spinner_type: SpinnerType;
  export {spinner_type as type};
</script>

{#if spinner_type === "plane"}
  <div class="sk-plane" />
{:else if spinner_type === "chase"}
  <div class="sk-chase">
    <div class="sk-chase-dot" />
    <div class="sk-chase-dot" />
    <div class="sk-chase-dot" />
    <div class="sk-chase-dot" />
    <div class="sk-chase-dot" />
    <div class="sk-chase-dot" />
  </div>
{:else if spinner_type === "bounce"}
  <div class="sk-bounce">
    <div class="sk-bounce-dot" />
    <div class="sk-bounce-dot" />
  </div>
{:else if spinner_type === "wave"}
  <div class="sk-wave">
    <div class="sk-wave-rect" />
    <div class="sk-wave-rect" />
    <div class="sk-wave-rect" />
    <div class="sk-wave-rect" />
    <div class="sk-wave-rect" />
  </div>
{:else if spinner_type === "pulse"}
  <div class="sk-pulse" />
{:else if spinner_type === "flow"}
  <div class="sk-flow">
    <div class="sk-flow-dot" />
    <div class="sk-flow-dot" />
    <div class="sk-flow-dot" />
  </div>
{:else if spinner_type === "swing"}
  <div class="sk-swing">
    <div class="sk-swing-dot" />
    <div class="sk-swing-dot" />
  </div>
{:else if spinner_type === "circle"}
  <div class="sk-circle">
    <div class="sk-circle-dot" />
    <div class="sk-circle-dot" />
    <div class="sk-circle-dot" />
    <div class="sk-circle-dot" />
    <div class="sk-circle-dot" />
    <div class="sk-circle-dot" />
    <div class="sk-circle-dot" />
    <div class="sk-circle-dot" />
    <div class="sk-circle-dot" />
    <div class="sk-circle-dot" />
    <div class="sk-circle-dot" />
    <div class="sk-circle-dot" />
  </div>
{:else if spinner_type === "circle-fade"}
  <!--
    Add "fading-circle-spinner" and "fading-circle" for compatibility with
    angular-spinkit used in old UI.
  -->
  <fading-circle-spinner class="sk-circle-fade fading-circle-spinner">
    <div class="sk-circle-fade-dot fading-circle" />
    <div class="sk-circle-fade-dot fading-circle" />
    <div class="sk-circle-fade-dot fading-circle" />
    <div class="sk-circle-fade-dot fading-circle" />
    <div class="sk-circle-fade-dot fading-circle" />
    <div class="sk-circle-fade-dot fading-circle" />
    <div class="sk-circle-fade-dot fading-circle" />
    <div class="sk-circle-fade-dot fading-circle" />
    <div class="sk-circle-fade-dot fading-circle" />
    <div class="sk-circle-fade-dot fading-circle" />
    <div class="sk-circle-fade-dot fading-circle" />
    <div class="sk-circle-fade-dot fading-circle" />
  </fading-circle-spinner>
{:else if spinner_type === "grid"}
  <div class="sk-grid">
    <div class="sk-grid-cube" />
    <div class="sk-grid-cube" />
    <div class="sk-grid-cube" />
    <div class="sk-grid-cube" />
    <div class="sk-grid-cube" />
    <div class="sk-grid-cube" />
    <div class="sk-grid-cube" />
    <div class="sk-grid-cube" />
    <div class="sk-grid-cube" />
  </div>
{:else if spinner_type === "fold"}
  <div class="sk-fold">
    <div class="sk-fold-cube" />
    <div class="sk-fold-cube" />
    <div class="sk-fold-cube" />
    <div class="sk-fold-cube" />
  </div>
{:else if spinner_type === "wander"}
  <div class="sk-wander">
    <div class="sk-wander-cube" />
    <div class="sk-wander-cube" />
    <div class="sk-wander-cube" />
  </div>
{/if}

<style>
  /**
   * import global styles within `<style>` tags, instead of `<script>` tags, so
   * that the ordering in the generated CSS file is correct. 
   */
  @import "spinkit/spinkit.css";
  @import "./Spinner.styl";

  * {
    --sk-size: 1em;
  }
</style>
