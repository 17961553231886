<script context="module" lang="ts">
  import {onDestroy, onMount} from "svelte";
  import intlTelInput from "intl-tel-input";
  import type {Options, Plugin} from "intl-tel-input";
  import utilsScript from "intl-tel-input/build/js/utils.js?url";

  async function init_options(
    preferred_countries?: string[],
    initial_country?: string | null,
    initial_country_url?: string | null
  ): Promise<Options> {
    // Configuration options are as per
    // https://github.com/jackocnr/intl-tel-input#options
    const options: Options = {utilsScript};

    if (!window.intlTelInputGlobals) {
      console.log("intl-tel-input: plugin not available.");
      return options;
    }

    // Retrieve the list of valid country codes so that we can ensure a
    // "clean" UI configuration in the presence of "bad" data.
    const valid_country_codes = window.intlTelInputGlobals.getCountryData().map((data) => data.iso2.toLowerCase());

    options.preferredCountries = [];

    // Copy and remove duplicates, ensuring that only valid country
    // codes are used.
    for (let code of preferred_countries ?? []) {
      code = code.toLowerCase();
      if (options.preferredCountries.indexOf(code) === -1 && valid_country_codes.indexOf(code) !== -1) {
        options.preferredCountries.push(code);
      }
    }

    if (options.preferredCountries.length === 0) {
      // Do NOT configure the preferred countries if the list is empty
      // as doing so would remove the "default preferred countries"
      // (which are at least reasonable), leaving the UI to default to
      // some country which is probably not expected to be a majority
      // user of this product.
      delete options.preferredCountries;
    }

    function set_initial_country(code?: string) {
      if (typeof code === "string" && code) {
        code = code.toLowerCase();
        if (valid_country_codes.indexOf(code) >= 0) {
          options.initialCountry = code;
        }
      }
    }

    if (initial_country) {
      set_initial_country(initial_country);
    } else if (initial_country_url) {
      const response = await fetch(initial_country_url);
      if (response.status === 200) {
        const data: {country_code?: string} = await response.json();
        set_initial_country(data?.country_code);
      }
    }

    return options;
  }

  function finalize_options(options: Options): void {
    // Ensure that the "initial country", if set, is always in the list of
    // "preferred countries".  Otherwise the flag selection UI, when first
    // selected, is centred on alphabetically close alternatives instead
    // of on the list of other preferred countries.
    if (options.initialCountry) {
      if (!options.preferredCountries) {
        options.preferredCountries = [];
      }
      if (options.preferredCountries.indexOf(options.initialCountry) === -1) {
        options.preferredCountries.splice(0, 0, options.initialCountry);
      }
    }
  }

  function update(plugin?: Plugin) {
    if (plugin) {
      const iti = plugin;

      function update_number(number?: string | null): void {
        if (number !== iti.getNumber()) {
          iti.setNumber(number ?? "");
        }
      }

      return {update_number};
    } else {
      return {};
    }
  }
</script>

<script lang="ts">
  let classes: string | null | undefined = undefined;
  // Optional "id" attribute for the `<input>` control
  export let id: string | null | undefined = undefined;
  // Optional "class" attribute for the `<input>` control
  export {classes as class};
  // Optional "required" attribute for the `<input>` control
  export let required: boolean | undefined = undefined;
  // Optional country coutry to initialize the country selector with
  export let initial_country: string | null | undefined = undefined;
  // Optional URL from which to retrieve the country code for the browser's
  // current location (GeoIP lookup)
  export let initial_country_url: string | null | undefined = undefined;
  // Optional "placeholder" attribute for the `<input>` control
  export let placeholder: string | null | undefined = undefined;
  // Optional list of country codes for countries to be shown "above the line"
  // in the country selector
  export let preferred_countries: string[] | undefined = undefined;
  // Bindable derived international number in E.164 format from raw user input
  // value and selected country (`bind:international_number`).
  export let international_number: string | null | undefined = undefined;

  const options: Promise<Options> = init_options(preferred_countries, initial_country, initial_country_url);

  function mount(options: Options, input: HTMLInputElement) {
    finalize_options(options);
    const plugin = intlTelInput(input, options);
    destroyable = plugin;
    plugin.promise.then(() => {
      function export_international_number() {
        const number = plugin.getNumber();
        if (number !== international_number) {
          international_number = number;
        }
      }

      // Export updates to the international number on either input or country
      // change events.
      input.oninput = export_international_number;
      input.addEventListener("countrychange", export_international_number);

      // Trigger reactive updates that are dependent on a ready plugin.
      iti = plugin;
    });
  }

  function init_or_update_number(
    update_number?: (number?: string | null) => void,
    international_number?: string | null
  ) {
    if (update_number) {
      // A defined `update_number` function means that we are now ready to apply
      // input to the phone control.
      if (!international_number && value && init_value) {
        // Keep any input the user has already input while the phone control was
        // loading.
        update_number(value);
      } else {
        // Update the current number.
        update_number(international_number);
      }

      // Ensure that we only "initialise" the input control once.
      init_value = false;
    }
  }

  $: ({update_number} = update(iti));
  $: init_or_update_number(update_number, international_number);

  onMount(() => {
    options.then((options) => {
      if (input && !iti) {
        mount(options, input);
      }
    });
  });

  let input: HTMLInputElement | undefined = undefined;
  let destroyable: Plugin | undefined = undefined;
  let iti: Plugin | undefined = undefined;
  let init_value = true;
  let value: string | null | undefined = undefined;

  onDestroy(() => {
    if (destroyable) {
      destroyable.destroy();
      destroyable = undefined;
    }
  });
</script>

<input bind:this={input} bind:value type="text" inputmode="tel" {id} class={classes} {placeholder} {required} />

<style>
  @import "intl-tel-input/build/css/intlTelInput.css";
  @import "./Phone.css";
</style>
