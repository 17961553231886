<script context="module" lang="ts">
  export type DropdownMode = "default" | "btn-group";
</script>

<script lang="ts">
  import {onDestroy} from "svelte";

  let dropdown_class: string | undefined = undefined;
  export let options: any[];
  export let tag: string | undefined = undefined;
  export {dropdown_class as class};
  export let toggle_tag: string | undefined = undefined;
  export let toggle_aria_label: string;
  export let toggle_tabindex: number;
  export let menu_class: string | undefined = undefined;
  export let mode: undefined | DropdownMode = undefined;
  export let selected: ((option: any) => void) | undefined = undefined;
  export let active: ((option: any) => boolean) | undefined = undefined;
  export let open = false;

  let element: HTMLElement | undefined = undefined;

  function toggle() {
    open = !open;
  }

  function close() {
    open = false;
  }

  function on_select(option: any) {
    if (selected) {
      selected(option);
    }
  }

  function on_toggle_keydown(e: KeyboardEvent) {
    if (e.key === "Enter" || e.key === " ") {
      toggle();
    } else if (e.key === "Escape") {
      close();
    }
  }

  function on_option_click(option: any) {
    return () => {
      on_select(option);
      close();
    };
  }

  function on_option_keydown(option: any) {
    return (e: KeyboardEvent) => {
      if (e.key === "Enter" || e.key === " " || e.key === "Escape") {
        if (e.key === "Enter" || e.key === " ") {
          on_select(option);
        }
        close();
      }
    };
  }

  function monitor_clicks(event: Event) {
    if (open && element && event.target instanceof Node && !element.contains(event.target)) {
      // The user clicked somewhere outside this dropdown while it was open.
      close();
    }
  }

  $: if (open) {
    document.addEventListener("click", monitor_clicks);
  } else {
    document.removeEventListener("click", monitor_clicks);
  }

  $: if (mode === "btn-group") {
    if (open) {
      element?.parentElement?.classList.add("open");
    } else {
      element?.parentElement?.classList.remove("open");
    }
  } else {
    element?.parentElement?.classList.remove("open");
  }

  onDestroy(() => {
    document.removeEventListener("click", monitor_clicks);
  });
</script>

{#if mode !== "btn-group"}
  <svelte:element this={tag || "span"} bind:this={element} class="dropdown {dropdown_class ?? ''}" class:open>
    <svelte:element
      this={toggle_tag || "span"}
      on:click={toggle}
      on:keydown={on_toggle_keydown}
      class="dropdown-toggle"
      tabindex={toggle_tabindex}
      role="button"
      data-toggle="dropdown"
      aria-label={toggle_aria_label}
      aria-haspopup="true"
      aria-expanded={open ? "true" : "false"}
    >
      <slot name="toggle" />
    </svelte:element>
    <ul class="dropdown-menu {menu_class || ''}">
      {#each options as option}
        <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
        <li
          on:click={on_option_click(option)}
          on:keydown|preventDefault={on_option_keydown(option)}
          class:active={active ? active(option) : false}
          class="text-left"
        >
          <slot name="option" {option} />
        </li>
      {/each}
    </ul>
  </svelte:element>
{:else}
  <button
    type="button"
    bind:this={element}
    on:click={toggle}
    on:keydown={on_toggle_keydown}
    class="btn btn-default dropdown-toggle"
    data-toggle="dropdown"
    tabindex={toggle_tabindex}
    aria-label={toggle_aria_label}
    aria-haspopup="true"
    aria-expanded={open ? "true" : "false"}
  >
    <slot name="toggle" />
  </button>
  <ul class="dropdown-menu {menu_class || ''}">
    {#each options as option}
      <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
      <li
        on:click={on_option_click(option)}
        on:keydown|preventDefault={on_option_keydown(option)}
        class:active={active ? active(option) : false}
        class="text-left"
      >
        <slot name="option" {option} />
      </li>
    {/each}
  </ul>
{/if}
